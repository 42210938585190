import { dispatchUpdateCurrentLanguage, getTenantConfig } from './utils/storeUtils';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: async (callback) => {
    try {
      const tenantConfig = getTenantConfig();
      const storedLanguage = localStorage.getItem('i18nLanguage');
      const defaultLanguage = !tenantConfig || !Object.keys(tenantConfig).length ? null : tenantConfig?.basic.allowedLanguages[0].value;
      await dispatchUpdateCurrentLanguage(storedLanguage);
      return callback(storedLanguage || defaultLanguage || 'en-CA');
    } catch (err) {
      return err.response;
    }
  },
  cacheUserLanguage: (lng) => {
    if (lng) localStorage.setItem('i18nLanguage', lng);
  },
};

export default languageDetector;
